/*-------------------------------------------careers css start here-------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.careers-container {
  padding-top: 75px;
}

.careers-header-section {
  color: white;
  background-image: linear-gradient(to right, #1B1B1B, #1B1B1B);
  /* background-image: linear-gradient(to right, #1B1B1B, #261F08); */
}

.careers-header-col {
  margin: 4rem 0rem;
  padding: 1.5rem 5rem;
}

.careers-header-col h1 {
  font-family: 'League Spartan', sans-serif;
  font-size: 52px;
  font-weight: 700;
  line-height: 67px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;
}

.careers-header-col p {
  font-family: 'League Spartan', sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #D4D4D4;

}


@media (max-width: 767px) {
  .careers-header-section {
    background-image: none;
  }

  .careers-header-col {
    margin: 2rem 0rem;
    padding: 1rem 2rem;
  }
}

/*-------------------------------------------careers css ends here-------------------------------------------------*/
/*-------------------------------------------open position css start here-------------------------------------------------*/

.open-positions-title {
  font-family: 'League Spartan', sans-serif;
  font-size: 52px;
  font-weight: 600;
  line-height: 67px;
  letter-spacing: 0em;
  text-align: center;
  color: #1B1B1B;
}

.position-col {
  border-radius: 30px;
  /* box-shadow: 1px 5px 10px rgba(0.5, 0.5, 0.5, 0.9); */
  box-shadow: 1px 5px 30px rgba(0, 0, 0, 0.1);
}

.position-col h2 {
  font-family: 'League Spartan', sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  color: #1B1B1B;
}

.position-col p {
  color: #787878;
  font-family: 'League Spartan', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.apply-button {
  font-family: 'League Spartan', sans-serif;
  font-size: 18px;
  font-weight: 800;
  color: white;
  background: #E2C15B;
  padding: 0.75rem 2.75rem;
}

.apply-button:hover{
  background: #1e1e1e;
  color: #e2c15b;
}
/*-------------------------------------------open position css end here-------------------------------------------------*/

/*-------------------------------------------------Our Process-------------------------------------------*/

.ourProcess h2 {
  font-family: 'League Spartan', sans-serif;
  font-size: 52px;
  font-weight: 600;
  line-height: 67px;
  letter-spacing: 0em;
  text-align: left;
}

.ourProcess p {
  font-family: 'League Spartan', sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #1B1B1B;
}


/*    ---------------------------------Why Us CSS Start here--------------------    */
.whyUs-row h2 {
  font-family: 'League Spartan', sans-serif;
  font-size: 52px;
  font-weight: 600;
  line-height: 67px;
  letter-spacing: 0em;
  text-align: center;
}

.whyUs-card {
  background: #E2C15B;
  border-radius: 45px;
  box-shadow: 1px 5px 10px rgba(0.5, 0.5, 0.5, 0.9);
  position: relative;
  max-height: inherit;
  overflow: hidden;
  font-size: 1.2rem;
  transition: background-color 0.3s, color 0.3s, font-size 0.3s;
  padding: 40px;
}

.whyUs-card-content p {
  font-family: 'League Spartan', sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  color: #000000;
}

.whyUs-hover-content {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  color: white;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
}

.whyUs-card:hover .whyUs-hover-content {
  display: block;
}

.whyUs-card img {
  width: 20px;
  height: 20px;
  float: right;
  position: absolute;
  right: 30px;
  bottom: 20px;
  transition: display 0.3s;
}

.whyUs-card:hover .whyUs-card-content p {
  opacity: 0;
  transition: opacity 0.3s;
}

.whyUs-card:hover .whyUs-card img {
  opacity: 0;
  transition: opacity 0.3s;
}

/* .whyUs-card:hover {
  background: black;
} */

.whyUs-card:hover .whyUs-hover-content {
  display: block;}

/*    ---------------------------------Why Us CSS ends here--------------------    */

/*    ---------------------------------Joining Fox CSS Starts here--------------------    */
.joining-fox p {
  font-family: 'League Spartan', sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
}

/*    ---------------------------------Joining Fox CSS ends here--------------------    */
.ClientReview-row h1 {
  color: #000000;
  font-family: 'League Spartan', sans-serif;
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: 59.5px;
}

.ClientReview-row p {
  font-family: 'League Spartan', sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #292D32;
}

.ClientReview-card {
  padding: 1.3rem;
}

.ClientReview-card p {
  font-family: 'League Spartan', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #292D32;
}

.ClientReview-card1 {
  border-radius: 35px;
  box-shadow: 1px 5px 10px rgba(0.5, 0.5, 0.5, 0.9);
  padding: 1.5rem;
}