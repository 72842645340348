@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');


.nav-link-custom:hover {
  color: #E2C15B;
}

.nav-link-custom {
  position: relative;
  /* color: #B5B5B5; */
  font-weight: 600;
  font-family: 'League Spartan', sans-serif;
  font-size: 17px;
  margin-right: 25px;
}

.nav-link-custom::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1.5px;
  background-color: #E2C15B;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
}

.nav-link-custom:hover::after {
  transform: scaleX(1);
}