/* ----------------------------Home page css start -------------------------------------------*/
/* @import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');

/* part-1 start */

.home-container {
  padding-top: 75px;
}

.home-row {
  color: white;
  background-color: #1B1B1B;
  /* background-size: auto;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-image: url(../../../public/assests/img/curveslines.svg), linear-gradient(to right, #1B1B1B, #261F08); */
}

.home-col {
  padding: 50px 20px;
}

.home-col h1,
.home-col p,
.home-col button {
  margin: 20px 70px;
}

.home-col h1 {
  font-family: 'League Spartan', sans-serif;
  font-size: 2rem;
}

.home-col p {
  font-family: 'League Spartan', sans-serif;
  font-size: 1.5rem;
  color: #b0b0b0;
  word-spacing: 0.2em;
  letter-spacing: 0em;
}

.home-col button {
  background: #e2c15b;
  /* border: solid 2px #e2c15b; */
  color: #1B1B1B;
  font-family: 'League Spartan', sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  padding: 0.8rem 2.5rem;
}

.home-col button:hover {
  background: #000000;
  color: #e2c15b;
  /* border: solid 2px #e2c15b; */
}

@media (max-width: 767px) {
  .home-row {
    background-image: linear-gradient(to right, #1B1B1B, #261F08);
  }

  .home-col {
    padding: 0;
  }

  .home-col h1,
  .home-col p,
  .home-col button {
    margin: 1rem 1rem;
  }
}

@media (min-width: 768px) and (max-width:1024px) {

  .home-col h1,
  .home-col p,
  .home-col button {
    margin: 20px 5px;
  }

}

/* part-1 ends */
/* ----------------------------Home page css ends ---------------------------------------------------------------*/

/* ----------------------------Home page - Our Services css starts  -------------------------------------------*/

/* part-1 css start */

.ourgoals-fcol {
  padding: 1rem;
}

.ourgoals-scol {
  /* padding: 1.8rem; */

  /* Responsive me ispr kaam krna hai*/
}

/* .ourgoals-row p {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  background-color: red;
} */

.ourgoals-scol p {
  color: #1B1B1B;
  font-family: 'League Spartan', sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  padding: 2rem 1.5rem 0rem 1.5rem;
}

/* 
@media (min-width: 768px) and (max-width:992px) {
  .ourgoals-scol {
    padding: 1rem 0rem 0rem 2rem;
  }
} */

/*--------------------------------------------------------*/
.ourservices-title {
  font-family: 'League Spartan', sans-serif;
  font-size: 52px;
  font-weight: 600;
  line-height: 67px;
  letter-spacing: 0em;
  padding-top: 6rem;
}

.ourclients-title {
  font-family: 'League Spartan', sans-serif;
  font-size: 52px;
  font-weight: 600;
  line-height: 67px;
  letter-spacing: 0em;
  /* padding-top: 6rem; */
}

.ourservices-col {
  /* display: inline-block; */
  /* margin: 10px; */
}

.ourservices-innerrow {
  border-radius: 20px;
  box-shadow: 0 0 4px #cecece;
}

.ourservices-col-div h2 {
  color: #292D32;
  font-size: 28px;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-align: left;
  font-family: 'League Spartan', sans-serif;
}

.ourservices-col-div p {
  font-family: 'League Spartan', sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #292D32;
  padding-right: 40px;
}

/* .ourservices-img-div img {
  height: 200px;
  width: 200px;
} */

/* ----------------------------Home page - Our Services css ends  -------------------------------------------------------------*/

/* ----------------------------Home page - Why css starts here -------------------------------------------------------------*/

/* part-1 */
.home-why-container {
  background-image: linear-gradient(to right, #1B1B1B, #261F08);
  border-radius: 40px;
  color: white;
}

/* part-1 ends*/

/* part-2 starts*/

.home-why-srow {
  padding: 1rem 2rem 8rem 2rem;
}

.home-why-col h5 {
  font-family: "League Spartan", sans-serif;
  font-size: 52px;
  font-weight: 600;
  color: #E9C85F;
}

.home-why-col p {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0em;
  color: #ECECEC;
}

.home-why-title {
  font-family: "League Spartan", sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #FFFFFF;
}

/* part-2 ends*/


/* part-3 starts*/

.home-why-new {
  margin-top: -90px;
}

.home-why-stats {
  background: #E9C85F;
  border-radius: 40px;
  padding: 50px 100px;
}

.container.home-why-new .home-why-stats h1 {
  color: black;
  font-family: "League Spartan", sans-serif;
  font-weight: 700;
  line-height: 50px;
  font-size: 65px;
}

.container.home-why-new .home-why-stats p {
  font-size: 22px;
  font-family: 'League Spartan', sans-serif;
  color: #292D32;
}

.served_client {
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (min-width: 768px) {

  .home-why-timg {
    margin-top: -50px;
    margin-bottom: 70px;
  }

  .served_client {}


}

/* part-3 ends*/

/* part-4 starts */

.leading-content h1 {
  font-family: "League Spartan", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-size: 53px;
  padding: 7rem 5rem 1rem 5rem;
}

.leading-content p {
  font-size: 22px;
  font-family: 'League Spartan', sans-serif;
  color: #292D32;
  /* padding: 0rem 10rem 0rem 10rem; */
}

.box-feature img {
  height: 83px;
  width: 82px;
}

.box-feature p {
  font-size: 18px;
  font-family: 'League Spartan', sans-serif;
  font-weight: 400;
}

.box-feature {
  background-color: #F5F5F5;
  border-radius: 20px;
  padding: 20px;
  /* margin-bottom: 30px; */
  cursor: pointer;
}

/* part-4 ends */

/*part-5 start */
.why-technologies h1 {
  font-family: "League Spartan", sans-serif;
  font-size: 53px;
  font-weight: 600;
}

.gradient-text {
  background: linear-gradient(90deg, #443A1C, #8B7739);
  background-clip: text;
  color: transparent;
  font-weight: bold;
  font-size: 52px;
  font-family: League Spartan;
}

.tech-react-col1 p {
  font-family: 'League Spartan', sans-serif;
  font-weight: 400;
  font-size: 22px;
  color: #292D32;
}

.tech-react-col2 {
  background: linear-gradient(45deg, #E2C15B, #EFD36D);
  border-radius: 40px;
}

.tech-content {
  /* background-color: red; */
}

.tech-react-col2 img {
  /* background-color: green; */
}

.tech-react-col2 h3 {
  font-family: 'League Spartan', sans-serif;
  font-weight: 800;
  font-size: 28px;
}

.tech-react-col2 p {
  font-family: 'League Spartan', sans-serif;
  font-weight: 400;
  font-size: 22px;
  color: #292D32;
}

/*part-5 ends */




/* ----------------------------Home page - Why css ends here -------------------------------------------------------------*/

/* ----------------------------Home page - Our client css starts here -------------------------------------------------------------*/

.ourclient-heading {
  font-family: League Spartan;
  font-size: 30px;
  font-weight: 600;
  line-height: 67px;
  letter-spacing: 0em;

}

.ourclient-mainContainer {
  border-radius: 60px;
  box-shadow: 1px 5px 10px rgba(0.5, 0.5, 0.5, 0.3);
}

/* ----------------------------Home page - client Review css starts here -------------------------------------------------------------*/

.ClientReview-row h1 {
  color: #000;
  font-family: League Spartan;
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: 59.5px;
}

.ClientReview-row .ClientReview-card {
  border-radius: 3rem;
  box-shadow: 1px 5px 10px rgba(0.5, 0.5, 0.5, 0.3);
}

.ClientReview-row p {
  font-size: 22px;
}

/* client Review Dots */

.slick-slider .slick-dots li button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #000;
  opacity: 0.7;
  margin: 0 5px;
}

.slick-slider .slick-dots {
  margin-bottom: -30px;
}

.slick-slider .slick-dots li.slick-active button {
  background-color: black;
  opacity: 1;
}

/* client Review Card box */

.slick-slider {
  width: 100%;
}

.testimonial-slide {
  padding: 8px;
}

.testimonial-name {
  font-size: 1.2rem;
  margin-bottom: 1px;
}

.testimonial-location {
  color: #949494;
  margin: 0;
}

.testimonial-image {
  width: 70px;
}

/* end client review */



@keyframes OurClient-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* ----------------------------Home page - client Review css ends here -------------------------------------------------------------*/

/* ----------------------------Home page - client css starts here -------------------------------------------------------------*/
.client-section {
  padding-top: 100px;
}

.client-featured-section {
  color: white;
  background-size: cover;
  background-position: center;
}

.client-featured-section h1 {
  font-size: 40px;
}

.client-featured-section h3 {
  font-size: 30px;
}

.client-featured-section p {
  font-size: 25px;
}

.client-featured-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 4rem;
}

@media (max-width: 768px) {
  .client-featured-content {
    padding: 1rem
  }
}

.client-featured-content-item {
  text-decoration: none;
  color: inherit;
}

.client-featured-content-item h4 {
  font-family: Lato;
  font-size: 24px;
  font-weight: 800;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  color: #1B1B1B;
}

.client-featured-content-item p {
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #787878;
}

.client-img {
  border-radius: 30px;
  width: 100%;
  height: auto;
}

.client-card-content {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

}

.client-contact-section {
  background: #E9C85F;
  color: black;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.client-contact-heading {
  font-family: League Spartan;
  font-size: 37px;
  font-weight: 500;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: left;
}


.client-contact-section-scol .client-contact-button {
  background: #000000;
  color: #ffffff;
  font-weight: bold;
  padding: 1.3rem 4rem;
  text-align: center;
  font-size: 1.5rem
}

.client-contact-section-scol .client-contact-button:hover {
  background: #000000;
  color: #e2c15b;
}

/* ----------------------------Home page - client css ends here -------------------------------------------------------------*/

div.modal-content {
  border-radius: 50px;
}


.form-label {
  font-size: 20px;
  font-weight: bold;
}

.form-group.contacts-form-group input {
  background: #F5F5F5;
}

.form-group.contacts-form-group button {
  background: #E2C15B;
  color: white;
  font-weight: bold;
}

.custom-placeholder::placeholder {
  color: #9F9F9F;
}



/* 
.gold-sponsors {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.sponsor-card {
  width: 200px;
  margin: 10px;
}

.sponsor-image {
  width: 100%;
  height: 5rem;
} */