/*----------------------------------------- Blog Page CSS Start Here -------------------------------------- */
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');


/* part-1 css start */

.blog-section {
  font-family: 'League Spartan',
    sans-serif;
  padding-top: 75px;
}

.blog-featured-section {
  color: white;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10rem 5rem 6rem 5rem;
}

.blog-featured-section h1 {
  font-size: 25px;
  color: #B5B5B5;
  font-size: 600;

}

.blog-featured-section h3 {
  font-size: 35px;
}

.blog-featured-section p {
  font-size: 25px;
  color: #B5B5B5;
  font-family: 'Lato', sans-serif;
}

.blog-fea-sec {
  padding-left: 35px;
}


@media (max-width: 820px) {
  .blog-featured-section {
    padding: 1rem;
  }

  .blog-fea-sec {
    padding-left: 0px;
  }

}

/* part-1 css ends*/

/* part-2 css start */

.blog-featured-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 5rem 5rem 5rem 5rem;
}

.blog-featured-content-item {
  text-decoration: none;
  color: inherit;
}

.blog-featured-content-item h4 {
  font-size: 25px;
  font-family: 'Lato', sans-serif;
  /* font-weight: 800; */
  line-height: 34px;
}

.blog-featured-content-item .blog-para1 {
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  color: #787878;
}

.blog-card-content {
  color: #787878;
  font-family: 'Lato', sans-serif;
}

.blog-img {
  border-radius: 30px;
  width: 100%;
  height: auto;
}


/* part-2 css ends */


/* part-3 css starts */

.blog-contact-section {
  background: #E9C85F;
  color: black;
  /* text-align: center; */
  /* justify-content: center; */
  align-items: center;
}

.blog-contact-heading {
  text-align: left;
  font-family: 'League Spartan';
  font-size: 35px;
  padding-left: 35px;

}

.blog-contact-button {
  background: black;
  color: white;
  padding: 0.8rem 2.5rem;
  font-size: 30px;
  font-family: 'Lato', sans-serif;
  font-weight: bold;

}

/* part-3 css ends */