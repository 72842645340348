/*-------------------------------------------Products css start here-------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');


.product-button button{
  background: #e2c15b;
  color: #1B1B1B;
  font-family: 'League Spartan', sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  padding: 1.2rem 3.5rem;
}


.box-feature {
  background: #f5f5f5;
  border-radius: 3rem;
  padding: 30px;
  margin-bottom: 30px;
  cursor: pointer;
  transition: transform .2s;
  box-shadow: 1px 5px 10px rgba(0.5, 0.5, 0.5, 0.3);
}

.leading-content-product h1{
  font-family: "League Spartan", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-size: 53px;
  padding: 1rem 5rem 1rem 5rem;
}

.leading-content-product p {
  font-size: 22px;
  font-family: 'League Spartan', sans-serif;
  color: #292D32;
  /* padding: 0rem 10rem 0rem 10rem; */
}

.visit-now-btn{
  background: #e2c15b;
  color: #1B1B1B;
  font-family: 'League Spartan', sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  padding: 1.2rem 3.5rem;
}

.visit-now-btn:hover {
  background: #1e1e1e;
  color: #e2c15b;
}

.visit-now-btn-top{
  background: #e2c15b;
  color: #1B1B1B;
  font-family: 'League Spartan', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  padding: 1.2rem 3.5rem;
}

.visit-now-btn-top:hover {
  background: #1e1e1e;
  color: #e2c15b;
}




.products-container {
  padding-top: 75px;

}

.products-row {
  color: white;
  background-image: linear-gradient(to right, #1B1B1B, #1B1B1B);
}


.products-col h1 {
  font-family: 'League Spartan', sans-serif;
  font-size: 50.5px;
  font-weight: 700;
  line-height: 67px;
  color: #FFFFFF;
  font-optical-sizing: auto;
  font-style: normal;
}

.products-col p {
  font-family: "Lato", sans-serif;
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  color: #D4D4D4;
  font-style: normal;
  padding: 0rem 1rem;
}

@media (max-width: 767px) {
  .products-col h1 {
    text-align: left;
    font-size: 45px;
    line-height: 50px;
  }

  .products-col p {
    text-align: left;
    padding: 0rem;
  }
}

@media (min-width: 767px) and (max-width:1024px) {
  /* .products-row {
    background-size: 70%;
  } 

  .products-col {
    margin: 1rem 0.5rem;
    padding-left: 1rem;
   }  */

}

/*-------------------------------------------Products css ends here-------------------------------------------------*/

/*-------------------------------------------Workprocess css starts here-------------------------------------------------*/

.box-feature-2 {
  background: #fff;
  border-radius: 4px;
  padding: 30px;
  margin-bottom: 30px;
  cursor: pointer;
  border-radius: 30px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}


.box-feature-2 h2 {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
}

.box-feature-2 p {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #292D32;
}

.box-feature-2 button {
  background: #e2c15b;
  color: #1B1B1B;
  font-family: 'League Spartan', sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  width: 8rem;
  /* padding: 0.8rem 5rem; */
}

.box-feature-2 img {
  width: 119px;
  height: 142px;
}

@media (max-width: 768px) and (min-width:992px) {
 
}




/* -------------------------------------------------------- */


.actual-workprocess h2 {
  font-family: 'League Spartan', sans-serif;
  line-height: 67px;
  font-weight: 600;
  font-style: normal;
  font-size: 52px;
  color: #1B1B1B;
}

.actual-workprocess h3 {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  color: #1B1B1B;
}

.actual-workprocess p {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
  color: #1B1B1B;
}

@media (max-width: 767px) {}

@media (max-width: 768px) and (min-width:992px) {}

/* ----------------------------------------------------------- */




/*-------------------------------------------Workprocess css ends here-------------------------------------------------*/

/*-------------------------------------------Our Products css start here-------------------------------------------------*/
.ourproducts-title {
  font-family: 'League Spartan',
    sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 52px;
  color: #1B1B1B;
  line-height: 67px;
  letter-spacing: 0em;
}

.ourproducts-card {
  border-radius: 50px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5);
}

.ourproducts-img {
  width: 350px;
  height: 70px;
}

.ourproducts-card-div p {
  color: #292D32;
  font-family: Lato;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}

.ourproducts-list {
  color: #292D32;
  font-family: Lato;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}

.visit {
  background: black;
  color: white;
  font-weight: bold;
  font-size: 22px;
  padding: 0.8rem 3rem;
  font-family: Lato;
}

/*-------------------------------------------Our Products css ends here-------------------------------------------------*/