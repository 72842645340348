/*----------------------------------------- Contact Page CSS Start Here -------------------------------------- */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@700&display=swap');



.contacts {
  padding-top: 75px;
  font-family: 'League Spartan', sans-serif;
}

.contacts-main {
  background: linear-gradient(to right, #1B1B1B, #1B1B1B);
  /* background: linear-gradient(to right, #1B1B1B, #261F08); */
  color: #FFFFFF;
}

.contacts-main img {
  padding-top: 3rem;
}

.contacts-main-div {
  padding: 7rem 9rem 0rem 7rem;
}

.contacts-main-div h1 {
  font-family: 'League Spartan', sans-serif;
  font-size: 60px;
  font-weight: 700;
  line-height: 67px;
  letter-spacing: 0em;
  text-align: left;
}

.contacts-main-div p {
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #D4D4D4;
}

/* -------------------------------------Contact Page CSS end Here---------------------------------------------------------------- */

/* -------------------------------------Location CSS start Here------------------------------------------------------------------ */
.contacts-info p {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  /* text-align: left; */
  color: #1B1B1B;
  margin-left: -45px;
  padding: 0rem 5rem 1rem 1rem;
}

.map-row iframe {
  width: 450px;
  height: 300px;
  display: flex;
  justify-content: start;
  align-items: start;
  /* width="450"
  height="300" */
}




/* -------------------------------------Location CSS ends Here------------------------------------------------------------------ */

/*----------------------------------------------------Form CSS--------------------------------------------------------------*/
.contacts-form {
  border-radius: 50px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5);
}

.contacts-form-group {
  padding: 2rem;
}

.contacts-form h2 {
  font-size: 34px;
  font-weight: 700;
}

.form-label {
  font-size: 20px;
  font-weight: bold;
}

.contacts-form input {
  background: #F5F5F5;
}

.contacts-form button {
  background: #E2C15B;
  color: white;
  font-weight: bold;
}

.custom-input {
  /* width: 500px; */
  height: 50px;
}

.custom-placeholder::placeholder {
  color: #9F9F9F;
}

/*----------------------------------------- Contact Page CSS ends Here -------------------------------------- */

@media (max-width: 992px) {
  .contacts-main-div {
    padding: 1rem;
  }

  .contacts-info p {
    text-align: center;
    padding: 0.5rem;
    margin-left: 0;
  }

  .map-row iframe {
    width: auto;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }

  .contacts-details .contacts-info,
  .contacts-details .contacts-icon {
    text-align: center;
    padding: 0.5rem;
    margin-left: 0px;
  }


}