@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');

body {
  font-family: 'Lato', sans-serif;

}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'League Spartan', sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #000000;
}


::-webkit-scrollbar-thumb {
  background-color: #e2c15b;
}


::-webkit-scrollbar-thumb:hover {
  background-color: #e2c15b;
}