@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');
body{
  font-family: 'League Spartan', sans-serif;
}



/* BlogInsider.css */
/* 
.bloginsider-container {
  padding-top: 90px;
  max-width: 750px;
  width: 100%;
}

.first-link {
  text-decoration: none;
  color: inherit;

}

.second-link {
  text-decoration: none;
  color: inherit;

} */

/*
.bloginsider-container {
   
    margin-left: 350px;
  }
  
  .bloginsider-row h1 {
    padding-top: 100px;
  }

  .bloginsider-row b {
    color: gray;
  }

  .bloginsider-row img {
    width: 550px;
    height: 250px;
    margin: 3rem 0rem;
  }

  .bloginsider-row-content{
    max-width: fit-content;
    width: 85%;
  }

  .link-row{
    width: 85%;
    margin: 3rem 0rem;
  }

  .link-row img{
    width: 270px;
    height: auto;
    border-radius: 30px;
  }

  .link-content{
    margin-top: 15px;
  }

  .first-link{
    text-decoration: none;
    color: inherit;
    padding-left: 0px;
  }

  .second-link{
    text-decoration: none;
    color: inherit;
    padding-left: 10px;
  }
  */



  /* all latest post in grin manner */
/* .latest_topic_grid{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
} */

/* BlogInsiderStyle.css */
.items-row {
  margin-right: 0; 
}

.client-feature-item {
  display: flex;
  flex-direction: column;
  height: 100%; 
}

.client-img {
  width: 100%; 
  height: auto; 
}

.client-feature-item div {
  flex: 1; 
}

.client-card-content {
  font-size: 1rem;
  color: gray;
}
.client-card-date {
  margin-top: 10px; 
  color: gray;
}